.service-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 20px;
    overflow-x: hidden; 
    box-sizing: border-box;
}

.service-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 40px;
    width: 100%;
    /* max-width: 100%; */
    max-width: 1300px;
    z-index: 2;
    box-sizing: border-box;
    margin: 0 auto;
}

.box {
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1300px) {
    .service-boxes {
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .service-boxes {
        gap: 20px;
    }
}