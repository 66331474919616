.descr-section {
    color: white;
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
  }
  
  .descr-title {
    font-size: 2.5rem;
    font-weight: 200;
    margin-bottom: 20px;
  }
  
  .highlight-text {
    color: #50A2CA; 
    font-weight: 700;
  }

  .break-line {
    width: 50px; 
  }
  
  .descr-paragraph {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 20px;
    font-weight: 300;
    color: rgba(255, 255, 255, 1); 
  }
  
  .descr-paragraph.emphasis {
    font-size: 1.2rem;
    color: white; 
    font-weight: 300;
  }
  
  strong {
    color: #50A2CA; 
  }
  
  @media (max-width: 768px) {
    .descr-title {
      font-size: 2rem;
    }
  
    .descr-paragraph {
      font-size: 1rem;
    }
  
    .descr-paragraph.emphasis {
      font-size: 1.1rem;
    }
  }