.faq-section {
    color: white;
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .faq-title {
    font-size: 2.5rem;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .faq-highlight {
    color: #50A2CA; 
    font-weight: 700;
  }
  
  .faq-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
  }
  
  .faq-column {
    flex: 1;
    min-width: 300px;
  }
  
  .faq-item {
    margin-bottom: 40px;
  }
  
  .faq-item h3 {
    color: #50A2CA;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 15px;
  }
  
  .faq-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
  }

  .faq-desc {
    font-weight: 200;
  }
  
  @media (max-width: 768px) {
    .faq-content {
      flex-direction: column;
    }
  
    .faq-title {
      font-size: 2rem;
    }
  }