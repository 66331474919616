.social-media-follow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    height: 90%;
    width: 100%;
}

.social-media-text {
    flex: 1;
    max-width: 45%;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    padding: 150px;
    justify-content: center;
    align-items: center;
}

.social-media-text h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
}

.social_text {
    color: #50a2ca; 
    font-weight: 700;
}

.social-media-items {
    flex: 2;
    display: flex;
    padding: 40px;
    max-width: 60%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px; 
}

.social-media-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3c4a5b;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 60vw;
    border: 1px solid rgba(255, 255, 255, 1);
}

.media-left {
    display: flex;
    align-items: center;
}

.media-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.media-info {
    display: flex;
    align-items: center;
}

.username {
    font-size: 16px;
    font-weight: 600;
}

.follow-section {
    display: flex;
    align-items: center;
    gap: 10px;
}

.bullet {
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
}

.follow-btn {
    color: #50a2ca;
    font-weight: 600;
    text-decoration: none;
}

.follow-btn:hover {
    text-decoration: underline;
}

@media (max-width: 1000px) {
    .social-media-text {
        max-width: 100%; 
        padding: 40px;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .social-media-items {
        max-width: 100%;
        padding: 20px;
    }

    .social-media-item {
        max-width: 100%;
        width: 60vw;
    }
}

@media (max-width: 768px) {
    .social-media-follow {
        flex-direction: column;
        padding: 20px;
    }

    .social-media-text {
        padding: 20px;
        text-align: center; 
    }

    .social-media-items {
        max-width: 100%; 
        padding: 20px 10px;
        gap: 15px;
    }

    .social-media-item {
        width: 100%; 
        padding: 15px; 
    }
}