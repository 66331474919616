body {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #fff; 
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    max-width: 100vw;
    background-color: transparent;
    transition: all 0.3s ease;
    z-index: 1000;
    position: relative;
    height: 50px;
}

.navbar-logo .logo {
    padding-top: 40px;
    height: 150px;
    width: auto;
    transition: height 0.3s ease;
    background-color: transparent;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 50px; 
    /* padding-right: 100px; */
}

.navbar-links a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: #ddd;
}

@media (max-width: 768px) {
    .navbar {
        padding: 10px 20px;
    }

    .navbar-links {
        gap: 20px;
    }
}
