.box {
    position: relative;
    width: 411.56px;
    height: 324.18px;
    background-image: url('../../assets/box_fit.png'); 
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px; 
    box-sizing: border-box;
    overflow: hidden;
}

.box-icon {
    position: absolute;
    left: 45px; 
    top: 35.74px; 
    width: 56px;   
    height: 50.23px; 
    filter: drop-shadow(0px 0px 4px #00D5FF);
    z-index: 2;
}

.box-title {
    position: absolute;
    left: 44px; 
    top: 97.74px;
    font-style: normal;
    width: 293px;
    height: 45.9px;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    color: #50A2CA;
    z-index: 2;
}

.break-line-box {
    position: absolute;
    width: 55px; 
    height: 0.65px;
    left: 46px;  
    top: 155.66px;
    z-index: 2;
}

.box-description {
    position: absolute;
    left: 46px;  
    top: 148.62px; 
    width:323px;
    height: 179.06px;
    font-weight: 300;
    font-size: 16px;
    line-height: 35px;
    color: #FFFFFF;
    z-index: 2;
}