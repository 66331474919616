.footer {
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    padding: 20px;
    font-weight: 300;
    box-sizing: border-box;
}

.footer p {
    margin: 0;
}