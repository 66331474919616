html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.contact-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    color: white;
    font-family: 'Montserrat', sans-serif;
    overflow: visible;
}

.contact-section::before, 
.contact-section::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    filter: blur(250px);
    z-index: -1; 
}

.contact-section::before {
    width: 500px;
    height: 580px;
    background: #004D6A;
    left: -200px;
    top: -50px;
}

.contact-section::after {
    width: 450px;
    height: 580px;
    background: #50A2CA;
    right: -200px;
    bottom: -100px;
}

.contact-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    position: relative;
    z-index: 1;
}

.contact-left {
    flex: 1;
    color: white;
    max-width: 400px;
}

.contact-left h2 {
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 20px;
}

.contact-left .highlight {
    color: #50A2CA;
    font-weight: 600;
}

.contact-left p {
    font-size: 16px;
    line-height: 1.8;
    font-weight: 200;
    margin-bottom: 20px;
}

.contact-form {
    flex: 2;
    max-width: 600px;
}

.contact-form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.contact-form-group label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.contact-form-group input,
.contact-form-group textarea {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 16px;
    outline: none;
}

.contact-form-group input::placeholder,
.contact-form-group textarea::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.contact-submit-btn {
    padding: 15px 40px;
    background-color: #50A2CA;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}

.contact-submit-btn:hover {
    background-color: #3399cc;
}

@media (max-width: 1024px) {
    .contact-content {
        flex-direction: column;
        text-align: center;
    }

    .contact-left {
        margin-bottom: 40px;
    }
}