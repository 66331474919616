.timeline-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    padding: 0 20px;
  }
  
  .timeline-title {
    font-size: 40px;
    font-weight: 400;
    padding-bottom: 20px;
    color: #fff;
    margin-bottom: 30px;
  }
  
  .timeline-title span {
    color: #50A2CA;
    font-weight: 700;
  }

 .timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 40px;
  margin-left: 50px;
  z-index: 0;
}

.timeline-connector {
  position: absolute;
  left: 59px;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #343a3c;
  height: calc(100% - 90px);
  z-index: -1;
}

.timeline-step {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
}

.timeline-number {
  background-color: #50A2CA;
  color: white;
  font-size: 24px;
  font-weight: 600;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 20px;
}

.timeline-content {
  font-size: 28px;
  line-height: 1.5;
  font-weight: 400;
}
.timeline-step:hover .timeline-content {
  color: #50A2CA;
  transition: color 0.3s ease;
}

.timeline_sub{
  color: #50A2CA;
  font-weight: 600;
}

@media (max-width: 768px) {
  .timeline-title {
      font-size: 30px;
  }
  .timeline-container {
      max-width: 100%;
      padding: 20px;
  }

  .timeline-step {
      margin-bottom: 40px;
  }

  .timeline-content {
      font-size: 16px;
  }

  .timeline-number {
      font-size: 20px;
      width: 35px;
      height: 35px;
  }
}