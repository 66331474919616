.home-container {
  margin-top: -80px;
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.home-content {
  color: #ffffff;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);
  font-family: 'Montserrat Alternates', sans-serif;
  display: flex;;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.landing-title {
  font-style: normal;
  font-size: calc(8vw + 20px);
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 4px 10px rgba(93, 124, 139, 0.92);
  margin-bottom: 20px;
}

.landing-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: calc(4vw + 10px);
  font-weight: 100;
  line-height: 1.5;
  white-space: pre-wrap;
  margin-bottom: 20px;
}

.landing-subtitle::before {
  content: "Invest in your home,\A"; 
  white-space: pre;
}

.landing-ai-gradient {
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, #073665 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  text-shadow: none;  
  padding: 0 20px;  
}

.landing-highlight-gradient {
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0.42) 62%, #ffffff 100%);
  font-weight: bold;
  background-clip: text;    
  -webkit-background-clip: text;
  font-weight: bold;
  filter: drop-shadow(0 0 5px #ffffff);
}

/* @media (max-width: 768px) {
  .landing-title {
    font-size: calc(12vw + 20px);
  }

  .landing-subtitle {
    font-size: calc(4vw + 10px);
  }
} */