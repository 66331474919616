.waitlist-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    padding: 40px;
    position: relative;
    height: 838px;
    color: white;
    text-align: center;
    background-repeat: no-repeat;
}

.waitlist-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.waitlist-title {
    font-size: 48px; 
    font-weight: bold;
    margin-bottom: 15px;
    line-height: 1.2;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0.42) 62%, #ffffff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    filter: drop-shadow(0 0 5px #ffffff);
}

.subtitle-form {
    font-size: 20px;
    margin-bottom: 30px;
    color: #ffffff;
    font-weight: 300;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5); 
}

.waitlist-form {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 70vw;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2); 
    border-radius: 10px; 
    padding: 5px; 
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.3); 
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.waitlist-email-input {
    width: 100%;
    flex: 1; 
    border: none; 
    padding: 15px 20px;
    border-radius: 10px;
    background-color: transparent;
    background: rgba(0, 0, 0, 0); 
    background-color: rgba(0, 0, 0, 0); 
    appearance: none;
    color: white;
    font-size: 18px;
    outline: none;
    box-shadow: none;
}

.waitlist-email-input::placeholder {
    color: #ccc;
}

.waitlist-submit-button {
    background-color: #50A2CA;
    color: white;
    padding: 15px 25px;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    font-size: 18px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
    box-shadow: 0 0 10px rgba(80, 162, 202, 0.5); 
}

.waitlist-submit-button:hover {
    background-color: #308fb2;
}